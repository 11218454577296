<template>
  <v-container class="pa-0 ma-0">
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 20; position: absolute; right: 20px"
      @click="generateReport"
      :loading="downloading"
      :disabled="!showDownloading"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="$printOptions.enableDownload"
      :preview-modal="$printOptions.previewModal"
      :pdf-quality="2"
      :manual-pagination="true"
      ref="html2Pdf"
      pdf-content-width="560px"
      :html-to-pdf-options="printOptions"
      @beforeDownload="downloading = true"
      @hasDownloaded="hasDownloaded"
    >
      <section slot="pdf-content">
        <div class="printing_container">
          <!-- PDF Content Here -->
          <EpisodeDetail />
        </div>
      </section>
    </vue-html2pdf>
    <EpisodeDetail />
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import EpisodeDetail from "./EpisodeDetail";

export default {
  components: {
    VueHtml2pdf,
    EpisodeDetail,
  },
  data() {
    return {
      printOptions: {
        margin: 0,
        filename: `report.pdf`,
        image: {
          type: "jpeg",
          quality: 1,
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a5",
          orientation: "portrait",
        },
      },
      downloading: false,
      showDownloading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showDownloading = true;
    }, 5000);
  },
  methods: {
    async generateReport() {
      this.downloading = true;
      await this.sleep(200);
      this.$refs.html2Pdf.generatePdf();
    },
    async hasDownloaded(blob) {
      var url = await this.handleHtml2PdfDownloaded(blob);
      this.downloading = false;
      if (url) this.shareLink(url);
    },
  },
};
</script>

<style lang="scss"></style>
